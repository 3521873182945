@media (min-width: 1440px) {
  .container {
    max-width: 1320px;
  }
}

dl,
dt,
dd,
ol,
ul,
li {
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
  display: block;
  font-size: 0px;
  height: 0px;
  line-height: 0;
  width: 100%;
  overflow: hidden;
}

::selection {
  background: #e31e36;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #e31e36; /* Firefox */
  color: #fff;
  text-shadow: none;
}

::-webkit-selection {
  background: #e31e36; /* Safari */
  color: #fff;
  text-shadow: none;
}

:active,
:focus {
  outline: none !important;
}

/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/

body {
  line-height: 1.5;
  color: #555;
  font-family: "Lato", sans-serif;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #e31e36 !important;
}

a img {
  border: none;
}

img {
  max-width: 100%;
}

iframe {
  width: 100%;
  border: 0 !important;
  overflow: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #444;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 30px 0;
  font-family: "Nunito", sans-serif !important;
}

h5,
h6 {
  margin-bottom: 20px;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 12px;
}

h4 {
  font-weight: 600;
}

h5,
h6 {
  font-weight: bold;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }
}

h1 > span:not(.nocolor):not(.badge),
h2 > span:not(.nocolor):not(.badge),
h3 > span:not(.nocolor):not(.badge),
h4 > span:not(.nocolor):not(.badge),
h5 > span:not(.nocolor):not(.badge),
h6 > span:not(.nocolor):not(.badge) {
  color: #1abc9c;
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
  margin-bottom: 30px;
}

small {
  font-family: "Lato", sans-serif;
}

.lh-100 {
  line-height: 100%;
}
.lh-36px {
  line-height: 36px;
}
.lh-17 {
  line-height: 1.7 !important;
}

.ws-pw {
  white-space: pre-wrap;
}

/* ----------------------------------------------------------------
	Helper Classes
-----------------------------------------------------------------*/

#wrapper {
  position: relative;
  float: none;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
  body:not(.stretched) #wrapper {
    max-width: 1200px;
  }
}

@media (min-width: 1440px) {
  body:not(.stretched) #wrapper {
    max-width: 1440px;
  }
}

.border-width-0,
.h-border-width-0:hover {
  border-width: 0px !important;
}

.border-width-1,
.h-border-width-1:hover {
  border-width: 1px !important;
}

.border-width-2,
.h-border-width-2:hover {
  border-width: 2px !important;
}

.border-width-3,
.h-border-width-3:hover {
  border-width: 3px !important;
}

.border-width-4,
.h-border-width-4:hover {
  border-width: 4px !important;
}

.border-width-5,
.h-border-width-5:hover {
  border-width: 5px !important;
}

.border-width-6,
.h-border-width-6:hover {
  border-width: 6px !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-solid {
  border-style: solid !important;
}

.border-double {
  border-style: double !important;
}

.h-border-primary:hover {
  border-color: #007bff !important;
}

.h-border-secondary:hover {
  border-color: #6c757d !important;
}

.h-border-success:hover {
  border-color: #28a745 !important;
}

.h-border-info:hover {
  border-color: #17a2b8 !important;
}

.h-border-warning:hover {
  border-color: #ffc107 !important;
}

.h-border-danger:hover {
  border-color: #dc3545 !important;
}

.h-border-light:hover {
  border-color: #f8f9fa !important;
}

.h-border-dark:hover {
  border-color: #343a40 !important;
}

.border-transparent,
.h-border-transparent:hover {
  border-color: transparent !important;
}

.border-default,
.h-border-default:hover {
  border-color: #eee !important;
}

.dark.border-default,
.dark .border-default,
.dark.h-border-default:hover .dark .h-border-default:hover {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.border-ts {
  -webkit-transition: border 0.5s ease;
  transition: border 0.5s ease;
}

/* ----------------------------------------------------------------
	Shadows
-----------------------------------------------------------------*/

.h-shadow-sm:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.h-shadow:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.h-shadow-lg:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.h-shadow-none:hover {
  box-shadow: none !important;
}

.shadow-ts {
  -webkit-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}

/* ----------------------------------------------------------------
	Bootstrap Utility Classes: Extended
-----------------------------------------------------------------*/

/*	Spacing Utilities */

.m-6 {
  margin: 5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 5rem !important;
}

.p-6 {
  padding: 5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 5rem !important;
}

@media (max-width: 575px) {
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important;
  }
}

@media (min-width: 576px) {
  .m-sm-6 {
    margin: 5rem !important;
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 5rem !important;
  }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 5rem !important;
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 5rem !important;
  }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 5rem !important;
  }

  .p-sm-6 {
    padding: 5rem !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important;
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 5rem !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important;
  }
}

@media (min-width: 768px) {
  .m-md-6 {
    margin: 5rem !important;
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: 5rem !important;
  }

  .mr-md-6,
  .mx-md-6 {
    margin-right: 5rem !important;
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 5rem !important;
  }

  .ml-md-6,
  .mx-md-6 {
    margin-left: 5rem !important;
  }

  .p-md-6 {
    padding: 5rem !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important;
  }
}

@media (min-width: 992px) {
  .m-lg-6 {
    margin: 5rem !important;
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 5rem !important;
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 5rem !important;
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 5rem !important;
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 5rem !important;
  }

  .p-lg-6 {
    padding: 5rem !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-6 {
    margin: 5rem !important;
  }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: 5rem !important;
  }

  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 5rem !important;
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 5rem !important;
  }

  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 5rem !important;
  }

  .p-xl-6 {
    padding: 5rem !important;
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important;
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important;
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important;
  }
}

/*	Custom Bootstrap Column */

.col-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5,
.col-xl-1-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-1-5 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 576px) {
  .col-sm-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 768px) {
  .col-md-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .col-lg-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  .col-xl-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

/*	Gutters */

.gutter-10 {
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: -10px;
}

.gutter-10 > .col,
.gutter-10 > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 10px;
}

.gutter-20 {
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: -20px;
}

.gutter-20 > .col,
.gutter-20 > [class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
}

.gutter-30 {
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: -30px;
}

.gutter-30 > .col,
.gutter-30 > [class*="col-"] {
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 30px;
}

.gutter-40 {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -40px;
}

.gutter-40 > .col,
.gutter-40 > [class*="col-"] {
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 40px;
}

.gutter-50 {
  margin-right: -25px;
  margin-left: -25px;
  margin-bottom: -50px;
}

.gutter-50 > .col,
.gutter-50 > [class*="col-"] {
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 50px;
}

.col-mb-30 {
  margin-bottom: -30px;
}

.col-mb-30 > .col,
.col-mb-30 > [class*="col-"] {
  padding-bottom: 30px;
}

.col-mb-50 {
  margin-bottom: -50px;
}

.col-mb-50 > .col,
.col-mb-50 > [class*="col-"] {
  padding-bottom: 50px;
}

.col-mb-80 {
  margin-bottom: -80px;
}

.col-mb-80 > .col,
.col-mb-80 > [class*="col-"] {
  padding-bottom: 80px;
}

@media (min-width: 576px) {
  .gutter-sm-10 {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  .gutter-sm-10 > .col,
  .gutter-sm-10 > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
  }

  .gutter-sm-20 {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }

  .gutter-sm-20 > .col,
  .gutter-sm-20 > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .gutter-sm-30 {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .gutter-sm-30 > .col,
  .gutter-sm-30 > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
  }

  .gutter-sm-40 {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .gutter-sm-40 > .col,
  .gutter-sm-40 > [class*="col-"] {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .gutter-sm-50 {
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -50px;
  }

  .gutter-sm-50 > .col,
  .gutter-sm-50 > [class*="col-"] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }

  .col-mb-sm-30 {
    margin-bottom: -30px;
  }

  .col-mb-sm-30 > .col,
  .col-mb-sm-30 > [class*="col-"] {
    padding-bottom: 30px;
  }

  .col-mb-sm-50 {
    margin-bottom: -50px;
  }

  .col-mb-sm-50 > .col,
  .col-mb-sm-50 > [class*="col-"] {
    padding-bottom: 50px;
  }

  .col-mb-sm-80 {
    margin-bottom: -80px;
  }

  .col-mb-sm-80 > .col,
  .col-mb-sm-80 > [class*="col-"] {
    padding-bottom: 80px;
  }
}

@media (min-width: 768px) {
  .gutter-md-10 {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  .gutter-md-10 > .col,
  .gutter-md-10 > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
  }

  .gutter-md-20 {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }

  .gutter-md-20 > .col,
  .gutter-md-20 > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .gutter-md-30 {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .gutter-md-30 > .col,
  .gutter-md-30 > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
  }

  .gutter-md-40 {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .gutter-md-40 > .col,
  .gutter-md-40 > [class*="col-"] {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .gutter-md-50 {
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -50px;
  }

  .gutter-md-50 > .col,
  .gutter-md-50 > [class*="col-"] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }

  .col-mb-md-30 {
    margin-bottom: -30px;
  }

  .col-mb-md-30 > .col,
  .col-mb-md-30 > [class*="col-"] {
    padding-bottom: 30px;
  }

  .col-mb-md-50 {
    margin-bottom: -50px;
  }

  .col-mb-md-50 > .col,
  .col-mb-md-50 > [class*="col-"] {
    padding-bottom: 50px;
  }

  .col-mb-md-80 {
    margin-bottom: -80px;
  }

  .col-mb-md-80 > .col,
  .col-mb-md-80 > [class*="col-"] {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .gutter-lg-10 {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  .gutter-lg-10 > .col,
  .gutter-lg-10 > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
  }

  .gutter-lg-20 {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }

  .gutter-lg-20 > .col,
  .gutter-lg-20 > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .gutter-lg-30 {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .gutter-lg-30 > .col,
  .gutter-lg-30 > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
  }

  .gutter-lg-40 {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .gutter-lg-40 > .col,
  .gutter-lg-40 > [class*="col-"] {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .gutter-lg-50 {
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -50px;
  }

  .gutter-lg-50 > .col,
  .gutter-lg-50 > [class*="col-"] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }

  .col-mb-lg-30 {
    margin-bottom: -30px;
  }

  .col-mb-lg-30 > .col,
  .col-mb-lg-30 > [class*="col-"] {
    padding-bottom: 30px;
  }

  .col-mb-lg-50 {
    margin-bottom: -50px;
  }

  .col-mb-lg-50 > .col,
  .col-mb-lg-50 > [class*="col-"] {
    padding-bottom: 50px;
  }

  .col-mb-lg-80 {
    margin-bottom: -80px;
  }

  .col-mb-lg-80 > .col,
  .col-mb-lg-80 > [class*="col-"] {
    padding-bottom: 80px;
  }
}

@media (min-width: 1200px) {
  .gutter-xl-10 {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  .gutter-xl-10 > .col,
  .gutter-xl-10 > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
  }

  .gutter-xl-20 {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }

  .gutter-xl-20 > .col,
  .gutter-xl-20 > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .gutter-xl-30 {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .gutter-xl-30 > .col,
  .gutter-xl-30 > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
  }

  .gutter-xl-40 {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .gutter-xl-40 > .col,
  .gutter-xl-40 > [class*="col-"] {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .gutter-xl-50 {
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -50px;
  }

  .gutter-xl-50 > .col,
  .gutter-xl-50 > [class*="col-"] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }

  .col-mb-xl-30 {
    margin-bottom: -30px;
  }

  .col-mb-xl-30 > .col,
  .col-mb-xl-30 > [class*="col-"] {
    padding-bottom: 30px;
  }

  .col-mb-xl-50 {
    margin-bottom: -50px;
  }

  .col-mb-xl-50 > .col,
  .col-mb-xl-50 > [class*="col-"] {
    padding-bottom: 50px;
  }

  .col-mb-xl-80 {
    margin-bottom: -80px;
  }

  .col-mb-xl-80 > .col,
  .col-mb-xl-80 > [class*="col-"] {
    padding-bottom: 80px;
  }
}

/* ----------------------------------------------------------------
	Height Utility Classes
-----------------------------------------------------------------*/

.min-vh-75 {
  min-height: 75vh !important;
}

.min-vh-60 {
  min-height: 60vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-40 {
  min-height: 40vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-0 {
  min-height: 0 !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.vh-25 {
  height: 25vh !important;
}

.h-60 {
  height: 60% !important;
}

.h-40 {
  height: 40% !important;
}

.max-vh-100 {
  max-height: 100vh !important;
}

.max-vh-75 {
  max-height: 75vh !important;
}

.max-vh-60 {
  max-height: 60vh !important;
}

.max-vh-50 {
  max-height: 50vh !important;
}

.max-vh-40 {
  max-height: 40vh !important;
}

.max-vh-25 {
  max-height: 25vh !important;
}

.max-vh-none {
  max-height: none !important;
}

@media (min-width: 576px) {
  .min-vh-sm-100 {
    min-height: 100vh !important;
  }

  .min-vh-sm-75 {
    min-height: 75vh !important;
  }

  .min-vh-sm-60 {
    min-height: 60vh !important;
  }

  .min-vh-sm-50 {
    min-height: 50vh !important;
  }

  .min-vh-sm-40 {
    min-height: 40vh !important;
  }

  .min-vh-sm-25 {
    min-height: 25vh !important;
  }

  .min-vh-sm-0 {
    min-height: 0 !important;
  }

  .vh-sm-100 {
    height: 100vh !important;
  }

  .vh-sm-75 {
    height: 75vh !important;
  }

  .vh-sm-60 {
    height: 60vh !important;
  }

  .vh-sm-50 {
    height: 50vh !important;
  }

  .vh-sm-40 {
    height: 40vh !important;
  }

  .vh-sm-25 {
    height: 25vh !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-60 {
    height: 60% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-40 {
    height: 40% !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .max-vh-sm-100 {
    max-height: 100vh !important;
  }

  .max-vh-sm-75 {
    max-height: 75vh !important;
  }

  .max-vh-sm-60 {
    max-height: 60vh !important;
  }

  .max-vh-sm-50 {
    max-height: 50vh !important;
  }

  .max-vh-sm-40 {
    max-height: 40vh !important;
  }

  .max-vh-sm-25 {
    max-height: 25vh !important;
  }

  .max-vh-sm-none {
    max-height: none !important;
  }
}

@media (min-width: 768px) {
  .min-vh-md-100 {
    min-height: 100vh !important;
  }

  .min-vh-md-75 {
    min-height: 75vh !important;
  }

  .min-vh-md-60 {
    min-height: 60vh !important;
  }

  .min-vh-md-50 {
    min-height: 50vh !important;
  }

  .min-vh-md-40 {
    min-height: 40vh !important;
  }

  .min-vh-md-25 {
    min-height: 25vh !important;
  }

  .min-vh-md-0 {
    min-height: 0 !important;
  }

  .vh-md-100 {
    height: 100vh !important;
  }

  .vh-md-75 {
    height: 75vh !important;
  }

  .vh-md-60 {
    height: 60vh !important;
  }

  .vh-md-50 {
    height: 50vh !important;
  }

  .vh-md-40 {
    height: 40vh !important;
  }

  .vh-md-25 {
    height: 25vh !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-60 {
    height: 60% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-40 {
    height: 40% !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .max-vh-md-100 {
    max-height: 100vh !important;
  }

  .max-vh-md-75 {
    max-height: 75vh !important;
  }

  .max-vh-md-60 {
    max-height: 60vh !important;
  }

  .max-vh-md-50 {
    max-height: 50vh !important;
  }

  .max-vh-md-40 {
    max-height: 40vh !important;
  }

  .max-vh-md-25 {
    max-height: 25vh !important;
  }

  .max-vh-md-none {
    max-height: none !important;
  }
}

@media (min-width: 992px) {
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }

  .min-vh-lg-75 {
    min-height: 75vh !important;
  }

  .min-vh-lg-60 {
    min-height: 60vh !important;
  }

  .min-vh-lg-50 {
    min-height: 50vh !important;
  }

  .min-vh-lg-40 {
    min-height: 40vh !important;
  }

  .min-vh-lg-25 {
    min-height: 25vh !important;
  }

  .min-vh-lg-0 {
    min-height: 0 !important;
  }

  .vh-lg-100 {
    height: 100vh !important;
  }

  .vh-lg-75 {
    height: 75vh !important;
  }

  .vh-lg-60 {
    height: 60vh !important;
  }

  .vh-lg-50 {
    height: 50vh !important;
  }

  .vh-lg-40 {
    height: 40vh !important;
  }

  .vh-lg-25 {
    height: 25vh !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-60 {
    height: 60% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-40 {
    height: 40% !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .max-vh-lg-100 {
    max-height: 100vh !important;
  }

  .max-vh-lg-75 {
    max-height: 75vh !important;
  }

  .max-vh-lg-60 {
    max-height: 60vh !important;
  }

  .max-vh-lg-50 {
    max-height: 50vh !important;
  }

  .max-vh-lg-40 {
    max-height: 40vh !important;
  }

  .max-vh-lg-25 {
    max-height: 25vh !important;
  }

  .max-vh-lg-none {
    max-height: none !important;
  }
}

@media (min-width: 1200px) {
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }

  .min-vh-xl-75 {
    min-height: 75vh !important;
  }

  .min-vh-xl-60 {
    min-height: 60vh !important;
  }

  .min-vh-xl-50 {
    min-height: 50vh !important;
  }

  .min-vh-xl-40 {
    min-height: 40vh !important;
  }

  .min-vh-xl-25 {
    min-height: 25vh !important;
  }

  .min-vh-xl-0 {
    min-height: 0 !important;
  }

  .vh-xl-100 {
    height: 100vh !important;
  }

  .vh-xl-75 {
    height: 75vh !important;
  }

  .vh-xl-60 {
    height: 60vh !important;
  }

  .vh-xl-50 {
    height: 50vh !important;
  }

  .vh-xl-40 {
    height: 40vh !important;
  }

  .vh-xl-25 {
    height: 25vh !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-60 {
    height: 60% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-40 {
    height: 40% !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .max-vh-xl-100 {
    max-height: 100vh !important;
  }

  .max-vh-xl-75 {
    max-height: 75vh !important;
  }

  .max-vh-xl-60 {
    max-height: 60vh !important;
  }

  .max-vh-xl-50 {
    max-height: 50vh !important;
  }

  .max-vh-xl-40 {
    max-height: 40vh !important;
  }

  .max-vh-xl-25 {
    max-height: 25vh !important;
  }

  .max-vh-xl-none {
    max-height: none !important;
  }
}

/* ----------------------------------------------------------------
	Position Utility Classes: Extended
-----------------------------------------------------------------*/

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

/* ----------------------------------------------------------------
	Lazy Loading
-----------------------------------------------------------------*/

.lazy {
  opacity: 0;
}

.lazy:not(.initial) {
  transition: opacity 1s;
}

.lazy.initial,
.lazy.lazy-loaded,
.lazy.lazy-error {
  opacity: 1;
}

img.lazy {
  max-width: 100%;
  height: auto;
}

img.lazy:not([src]) {
  visibility: hidden;
}

/* ----------------------------------------------------------------
	Sections
-----------------------------------------------------------------*/

.section {
  position: relative;
  width: 100%;
  margin: 60px 0;
  padding: 60px 0;
  background-color: #f9f9f9;
  overflow: hidden;
}

.parallax {
  background-color: transparent;
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  overflow: hidden;
  will-change: transform;
}

.mobile-parallax,
.video-placeholder {
  background-size: cover !important;
  background-attachment: scroll !important;
  background-position: center center !important;
}

.section .container {
  z-index: 2;
}

.section .container + .video-wrap {
  z-index: 1;
}

.revealer-image {
  position: relative;
  bottom: -100px;
  transition: bottom 0.3s ease-in-out;
  -webkit-transition: bottom 0.3s ease-in-out;
  -o-transition: bottom 0.3s ease-in-out;
}

.section:hover .revealer-image {
  bottom: -50px;
}

/* ----------------------------------------------------------------
	Columns & Grids
-----------------------------------------------------------------*/

.postcontent,
.sidebar {
  position: relative;
}

/* ----------------------------------------------------------------
	Flex Slider
-----------------------------------------------------------------*/

.fslider,
.fslider .flexslider,
.fslider .slider-wrap,
.fslider .slide,
.fslider .slide > a,
.fslider .slide > img,
.fslider .slide > a > img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
}

.fslider.h-100 .flexslider,
.fslider.h-100 .slider-wrap,
.fslider.h-100 .slide {
  height: 100% !important;
}

.fslider {
  min-height: 32px;
}

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
  border: none;
}

.slider-wrap,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
}

.flexslider {
  position: relative;
  margin: 0;
  padding: 0;
}

.flexslider .slider-wrap > .slide {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slider-wrap img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.slider-wrap::after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slider-wrap {
  display: block;
}

* html .slider-wrap {
  height: 1%;
}

.no-js .slider-wrap > .slide:first-child {
  display: block;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/

#top-bar {
  position: relative;
  border-bottom: 1px solid #eee;
  font-size: 13px;
}

/* Top Links
---------------------------------*/

.top-links {
  position: relative;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-bottom: 1px solid #eee;
}

@media (min-width: 768px) {
  .top-links {
    border-bottom: 0;
  }
}

.top-links-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
}

.top-links-item {
  position: relative;
  border-left: 1px solid #eee;
}

.top-links-item:first-child,
.top-links-sub-menu .top-links-item {
  border-left: 0 !important;
}

.top-links-item > a {
  display: block;
  padding: 12px;
  font-size: 0.75rem;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #666;
}

.top-links-item > a > i {
  vertical-align: top;
}

.top-links-item > a > i.icon-angle-down {
  margin: 0 0 0 5px !important;
}

.top-links-item > a > i:first-child {
  margin-right: 3px;
}

.top-links-item.full-icon > a > i {
  top: 2px;
  font-size: 0.875rem;
  margin: 0;
}

.top-links-item:hover {
  background-color: #eee;
}

.top-links-sub-menu,
.top-links-section {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  list-style: none;
  z-index: -1;
  line-height: 1.5;
  background: #fff;
  border: 0;
  top: 100%;
  left: -1px;
  width: 140px;
  margin-top: 10px;
  border: 1px solid #eee;
  border-top-color: #1abc9c;
  box-shadow: 0px 13px 42px 11px rgba(0, 0, 0, 0.05);
  transition: opacity 0.25s ease, margin 0.2s ease;
}

.top-links:not(.on-click) .top-links-item:hover > .top-links-sub-menu,
.top-links:not(.on-click) .top-links-item:hover > .top-links-section,
.top-links.on-click .top-links-sub-menu,
.top-links.on-click .top-links-section {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  pointer-events: auto;
  z-index: 499;
}

.top-links.on-click .top-links-sub-menu,
.top-links.on-click .top-links-section {
  display: none;
}

.top-links-sub-menu .top-links-sub-menu {
  top: -1px;
  left: 100%;
}

.top-links-sub-menu .top-links-item:not(:first-child) {
  border-top: 1px solid #f5f5f5;
}

.top-links-sub-menu .top-links-item:hover {
  background-color: #f9f9f9;
}

.top-links-sub-menu .top-links-item > a {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 0.6875rem;
}

.top-links-sub-menu .top-links-item > a > img {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.top-links-sub-menu.top-demo-lang .top-links-item > a > img {
  top: 4px;
  width: 16px;
  height: 16px;
}

.top-links-sub-menu .top-links-item > a > i.icon-angle-down {
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: auto;
  right: 10px;
  transform: translateY(-50%) rotate(-90deg);
}

.top-links-section {
  padding: 25px;
  left: 0;
  width: 280px;
}

.top-links-section.menu-pos-invert {
  left: auto;
  right: 0;
}

/* Top Social
-----------------------------------------------------------------*/

#top-social {
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin: 0;
}

#top-social li {
  position: relative;
  list-style: none;
}

#top-social li {
  border-left: 1px solid #eee;
}

#top-social li:first-child {
  border-left: 0 !important;
}

#top-social a {
  display: -ms-flexbox;
  display: flex;
  width: auto;
  overflow: hidden;
  font-weight: bold;
  color: #666;
}

#top-social li a:hover {
  color: #fff !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

#top-social li .ts-icon,
#top-social li .ts-text {
  display: block;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 44px;
  line-height: 44px;
}

#top-social li .ts-icon {
  width: 40px;
  text-align: center;
  font-size: 0.875rem;
}

#top-social li .ts-text {
  max-width: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#top-social li a:hover .ts-text {
  max-width: 200px;
  padding-right: 12px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* Top Login
-----------------------------------------------------------------*/

#top-login {
  margin-bottom: 0;
}

#top-login .checkbox {
  margin-bottom: 10px;
}

#top-login .form-control {
  position: relative;
}

#top-login .form-control:focus {
  border-color: #ccc;
}

#top-login .input-group#top-login-username {
  margin-bottom: -1px;
}

#top-login #top-login-username input,
#top-login #top-login-username .input-group-addon {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#top-login .input-group#top-login-password {
  margin-bottom: 10px;
}

#top-login #top-login-password input,
#top-login #top-login-password .input-group-addon {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/

#header {
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
}

#header-wrap {
  position: relative;
  background-color: #fff;
  width: 100%;
}

.header-wrap-clone {
  display: none;
}

.header-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 0;
  height: 70px;
  justify-content: space-between;
}

@media (max-width: 1199px) {
  .primary-menu {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .mobileMenuCss {
    display: none !important;
  }
  .search {
    max-width: 43%;
  }
}

@media (min-width: 992px) {
  #header-wrap {
    position: fixed;
    height: auto;
    z-index: 299;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    transition: height 0.2s ease 0s, background-color 0.3s ease 0s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: left, top, transform;
  }

  #top-bar + #header:not(.sticky-header) #header-wrap,
  #slider + #header:not(.sticky-header) #header-wrap,
  .floating-header:not(.sticky-header) #header-wrap,
  div + #header-wrap,
  .no-sticky #header-wrap,
  [data-sticky-offset]:not([data-sticky-offset="full"]):not(.sticky-header)
    #header-wrap,
  body:not(.stretched) #header:not(.sticky-header) #header-wrap {
    position: absolute;
  }

  .header-wrap-clone {
    display: block;
    height: calc(100px + 1px);
  }

  .header-size-sm .header-wrap-clone {
    height: calc(60px + 1px);
  }

  .header-size-md .header-wrap-clone {
    height: calc(80px + 1px);
  }

  .header-size-lg .header-wrap-clone {
    height: calc(120px + 1px);
  }

  .header-row {
    position: initial;
  }

  #header.transparent-header,
  #header.transparent-header:not(.sticky-header) #header-wrap {
    background: transparent;
    border-bottom: 1px solid transparent;
    z-index: 299;
  }

  #header.transparent-header.semi-transparent #header-wrap {
    background-color: rgba(255, 255, 255, 0.8);
  }

  #header.transparent-header.floating-header {
    margin-top: 60px;
  }

  .transparent-header.floating-header .container {
    background-color: #fff;
    padding: 0 30px;
  }

  .transparent-header.floating-header:not(.sticky-header) .container {
    position: relative;
  }

  #header + .include-header {
    margin-top: calc(-100px - 2px);
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
  }

  #header.header-size-sm + .include-header {
    margin-top: calc(-60px - 2px);
  }

  #header.header-size-md + .include-header {
    margin-top: calc(-80px - 2px);
  }

  #header.header-size-lg + .include-header {
    margin-top: calc(-120px - 2px);
  }

  #header.floating-header + .include-header {
    margin-top: calc(-160px - 2px);
  }

  #header.transparent-header + .page-title-parallax.include-header .container {
    z-index: 5;
    padding-top: 100px;
  }

  #header.full-header:not(.transparent-header) {
    border-bottom-color: #eee;
  }

  .stretched .full-header #header-wrap > .container {
    width: 100%;
    max-width: none;
    padding: 0 30px;
  }

  #header.transparent-header.full-header #header-wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  #slider + #header.transparent-header.full-header #header-wrap {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
  }

  #logo img {
    -webkit-transition: height 0.4s ease, padding 0.4s ease, margin 0.4s ease,
      opacity 0.3s ease;
    transition: height 0.4s ease, padding 0.4s ease, margin 0.4s ease,
      opacity 0.3s ease;
  }

  #logo img {
    max-height: 100%;
  }
}

#logo {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  margin-right: auto;
  max-height: 100%;
}

#logo a {
  color: #000;
  font-family: "Nunito", sans-serif !important;
  font-size: 36px;
}

#logo img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 100px;
}

.primary-menu {
  display: -ms-flexbox;
  display: flex;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.primary-menu + .primary-menu {
  border-top: 1px solid #eee;
}

.menu-container {
  display: none;
  list-style: none;
  margin: 0;
  width: 100%;
}

.menu-item {
  position: relative;
}

.menu-item:not(:first-child) {
  border-top: 1px solid #eee;
}

.menu-link {
  display: block;
  line-height: 22px;
  padding: 14px 5px;
  color: #444;
  font-weight: 800;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Nunito", sans-serif !important;
  margin-right: -2px;
}

.menu-link span {
  display: none;
}

.menu-link i {
  position: relative;
  top: -1px;
  font-size: 0.875rem;
  width: 16px;
  text-align: center;
  margin-right: 6px;
  vertical-align: top;
}

.menu-item:hover > .menu-link,
.menu-item.current > .menu-link {
  color: #1abc9c;
}

.menu-item.sub-menu > .menu-link > div {
}

.sub-menu-container,
.mega-menu-content {
  position: relative;
  padding-left: 15px;
  border-top: 1px solid #eee;
  list-style: none;
  margin-bottom: 0;
}

.sub-menu-container .menu-item:not(:first-child) {
  border-top: 1px solid #eee;
}

.sub-menu-container .menu-item > .menu-link {
  position: relative;
  padding: 11px 5px;
  font-size: 0.75rem;
  font-weight: 400;
  color: #666;
  letter-spacing: 0;
  font-family: "Lato", sans-serif;
}

.sub-menu-container .menu-item:hover > .menu-link {
  font-weight: 700;
}

.sub-menu-container .menu-link div {
  position: relative;
}

.menu-item .sub-menu-trigger {
  display: block;
  position: absolute;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  top: 9px;
  right: 0;
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  -webkit-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}

.menu-link div > i.icon-angle-down {
  display: none;
}

.sub-menu-container .sub-menu-trigger {
  font-size: 10px;
}

.sub-menu-container .menu-item .sub-menu-trigger {
  top: 6px;
}

/* ----------------------------------------------------------------
	Mega Menu
-----------------------------------------------------------------*/

.mega-menu:not(.mega-menu-full) .mega-menu-content .container {
  max-width: none;
}

.mega-menu-content {
  padding-left: 0;
}

@media (max-width: 991.98px) {
  .primary-menu {
    max-width: 100%;
  }

  .primary-menu > .container {
    max-width: none;
    padding: 0;
  }

  .mega-menu-content .sub-menu-container {
    border-top: 1px solid #eee;
  }

  .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
    display: block !important;
  }

  .mega-menu-content .mega-menu-column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .mega-menu-column .widget {
    padding: 30px 0;
  }
}

.mega-menu-content .mega-menu-column:first-child {
  border-top: 0;
}

/* Off Canvas Menu
-----------------------------------------------------------------*/

@media (max-width: 991.98px) {
  #header:not(.sticky-header) .mobile-menu-off-canvas .menu-container {
    position: fixed;
    display: block;
    z-index: 499;
    width: 280px;
    height: 100%;
    height: calc(100vh);
    max-height: none !important;
    margin: 0 !important;
    left: 0 !important;
    top: 0 !important;
    padding: 35px 25px 60px !important;
    background-color: #fff;
    border-right: 1px solid #eee !important;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate(-280px, 0);
    -o-transform: translate(-280px, 0);
    transform: translate(-280px, 0);
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
  }

  #header:not(.sticky-header)
    .mobile-menu-off-canvas.from-right
    .menu-container {
    left: auto !important;
    right: 0;
    border-right: 0 !important;
    border-left: 1px solid #eee !important;
    -webkit-transform: translate(280px, 0);
    -o-transform: translate(280px, 0);
    transform: translate(280px, 0);
  }

  #header:not(.sticky-header) .mobile-menu-off-canvas .menu-container {
    -webkit-transition: -webkit-transform 0.3s ease-in-out, opacity 0.2s ease !important;
    -o-transition: -o-transform 0.3s ease-in-out, opacity 0.2s ease !important;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease !important;
  }

  #header:not(.sticky-header) .mobile-menu-off-canvas .menu-container.d-block {
    -webkit-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@media (min-width: 992px) {
  .primary-menu {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .primary-menu + .primary-menu {
    border-top: 0;
  }

  #primary-menu-trigger,
  #page-menu-trigger {
    display: none;
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }

  .menu-container:not(.mobile-primary-menu) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
  }

  .full-header .primary-menu .menu-container {
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    border-right: 1px solid #eee;
  }

  .transparent-header.full-header .primary-menu .menu-container {
    border-right-color: rgba(0, 0, 0, 0.1);
  }

  .mega-menu {
    position: inherit;
  }

  .menu-container > .menu-item:not(:first-child) {
    margin-left: 2px;
  }

  .menu-item:not(:first-child) {
    border-top: 0;
  }

  .menu-item.menu-item-important .menu-link > div::after {
    content: "";
    display: inline-block;
    position: relative;
    top: -5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #dc3545;
  }

  .menu-link {
    padding: 24px 10px;
    -webkit-transition: margin 0.4s ease, padding 0.4s ease;
    -o-transition: margin 0.4s ease, padding 0.4s ease;
    transition: margin 0.4s ease, padding 0.4s ease;
  }
}

/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/

#content {
  position: relative;
  background-color: #fff;
}

#content p {
  line-height: 1.8;
}

.content-wrap {
  position: relative;
  padding: 100px 0 0;
  overflow: hidden;
}

@media (max-width: 767px) {
  .content-wrap {
    padding: 130px 0 0;
  }

  .content-wrap .container h1 {
    margin-top: 9px;
  }
}

.content-wrap.embedded {
  padding: 0 !important;
}

#content .container {
  position: relative;
}

.button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  margin: 5px;
  padding: 8px 22px;
  font-size: 0.875rem;
  line-height: 24px;
  background-color: #1abc9c;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

body:not(.device-touch) .button {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.button.button-dark {
  background-color: #444;
}

.button.button-light {
  background-color: #eee;
  color: #333;
  text-shadow: none !important;
}

.button:hover {
  background-color: #444;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.button.button-dark:hover {
  background-color: #1abc9c;
}

.button.button-mini {
  padding: 4px 14px;
  font-size: 11px;
  line-height: 20px;
}

.button.button-small {
  padding: 7px 17px;
  font-size: 12px;
  line-height: 20px;
}

.button.button-large {
  padding: 8px 26px;
  font-size: 1rem;
  line-height: 30px;
}

.button.button-xlarge {
  padding: 9px 32px;
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 34px;
}

.button.button-desc {
  text-align: left;
  padding: 24px 34px;
  font-size: 1.25rem;
  height: auto;
  line-height: 1;
  font-family: "Nunito", sans-serif !important;
}

.button.button-desc span {
  display: block;
  margin-top: 10px;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: "PT Serif", serif;
  font-style: italic;
  text-transform: none;
}

.button i {
  position: relative;
  margin-right: 5px;
  width: 16px;
  text-align: center;
}

.button.text-right i {
  margin: 0 0 0 5px;
}

.button-mini i {
  margin-right: 3px;
}

.button-mini.text-right i {
  margin: 0 0 0 3px;
}

.button-small i {
  margin-right: 4px;
}

.button-small.text-right i {
  margin: 0 0 0 4px;
}

.button-xlarge i {
  margin-right: 8px;
}

.button-xlarge.text-right i {
  margin: 0 0 0 8px;
}

.button.button-desc i {
  font-size: 48px;
  width: 48px;
  text-align: center;
  margin-right: 12px;
}

.button.button-desc.text-right i {
  margin: 0 0 0 12px;
}

.button.button-desc div {
  display: inline-block;
}

.button.button-rounded {
  border-radius: 3px;
}

.text-container {
  column-count: 1;
}

.text-container.two-columns {
  column-count: 2;
}

@media (max-width: 768px) {
  .text-container.two-columns {
    column-count: 1;
  }
}

/* Buttons - Border
-----------------------------------------------------------------*/

.button.button-border {
  border: 2px solid #444;
  background-color: transparent;
  color: #333;
  font-weight: 700;
  text-shadow: none;
}

.button.button-border-thin {
  border-width: 1px;
}

.button.button-border.button-desc {
  line-height: 1;
}

.button.button-border:not(.button-fill):hover {
  background-color: #444;
  color: #fff;
  border-color: transparent !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.button.button-border.button-light {
  border-color: #fff;
  color: #fff;
}

.button.button-border.button-light:hover {
  background-color: #fff;
  color: #333;
  text-shadow: none;
}

/* Buttons - Border
-----------------------------------------------------------------*/

.button.button-3d {
  border-radius: 3px;
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.button.button-3d:hover {
  background-color: #1abc9c;
  opacity: 0.9;
}

.button.button-3d.button-light:hover,
.button.button-reveal.button-light:hover {
  text-shadow: none;
  color: #333;
}

/* Buttons - Icon Reveal
-----------------------------------------------------------------*/

.button.button-reveal {
  padding-left: 28px;
  padding-right: 28px;
  overflow: hidden;
}

.button.button-reveal i {
  display: block;
  position: absolute;
  top: 0;
  left: -32px;
  width: 32px;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.button.button-reveal.button-border i {
  top: -2px;
}

.button.button-reveal.text-right i {
  left: auto;
  right: -32px;
}

.button.button-reveal.button-3d i,
.button.button-reveal.button-rounded i {
  border-radius: 3px 0 0 3px;
}

.button.button-reveal.button-3d.text-right i,
.button.button-reveal.button-rounded.text-right i {
  border-radius: 0 3px 3px 0;
}

.button.button-reveal span {
  display: inline-block;
  position: relative;
  left: 0;
}

body:not(.device-touch) .button.button-reveal i,
body:not(.device-touch) .button.button-reveal span {
  -webkit-transition: left 0.3s ease, right 0.3s ease;
  -o-transition: left 0.3s ease, right 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease;
}

.button.button-reveal.button-mini {
  padding-left: 17px;
  padding-right: 17px;
}

.button.button-reveal.button-mini i {
  left: -22px;
  width: 22px;
}

.button.button-reveal.button-mini.text-right i {
  left: auto;
  right: -22px;
}

.button.button-reveal.button-small {
  padding-left: 22px;
  padding-right: 22px;
}

.button.button-reveal.button-small i {
  left: -26px;
  width: 26px;
}

.button.button-reveal.button-small.text-right i {
  left: auto;
  right: -26px;
}

.button.button-reveal.button-large {
  padding-left: 32px;
  padding-right: 32px;
}

.button.button-reveal.button-large i {
  left: -38px;
  width: 38px;
}

.button.button-reveal.button-large.text-right i {
  left: auto;
  right: -38px;
}

.button.button-reveal.button-xlarge {
  padding-right: 40px;
  padding-left: 40px;
}

.button.button-reveal.button-xlarge i {
  left: -44px;
  width: 44px;
}

.button.button-reveal.button-xlarge.text-right i {
  left: auto;
  right: -44px;
}

.button.button-reveal:hover i {
  left: 0;
}

.button.button-reveal.text-right:hover i {
  left: auto;
  right: 0;
}

.button.button-reveal:hover span {
  left: 16px;
}

.button.button-reveal.button-mini:hover span {
  left: 11px;
}

.button.button-reveal.button-small:hover span {
  left: 13px;
}

.button.button-reveal.button-large:hover span {
  left: 19px;
}

.button.button-reveal.button-xlarge:hover span {
  left: 22px;
}

.button.button-reveal.text-right:hover span {
  left: -16px;
}

.button.button-reveal.button-mini.text-right:hover span {
  left: -11px;
}

.button.button-reveal.button-small.text-right:hover span {
  left: -13px;
}

.button.button-reveal.button-large.text-right:hover span {
  left: -19px;
}

.button.button-reveal.button-xlarge.text-right:hover span {
  left: -22px;
}

/* Buttons - Promo 100% Width
-----------------------------------------------------------------*/

.button.button-full {
  display: block;
  width: 100%;
  white-space: normal;
  margin: 0;
  height: auto;
  line-height: 1.6;
  padding: 30px 0;
  font-size: 30px;
  font-weight: 300;
  text-transform: none;
  border-radius: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.button.button-full.button-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.button.button-full strong {
  font-weight: 700;
  border-bottom: 2px solid #eee;
}

body:not(.device-touch) .button.button-full strong {
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.button.button-full.button-light strong {
  border-bottom-color: #333;
}

.button.button-full.button-light:hover strong {
  border-bottom-color: #eee;
}

.successmsg {
  background: #d4edda;
  color: #155724;
}

.errormsg {
  background: #f8d7da;
  color: #721c24;
}

.infomsg {
  background: #d1ecf1;
  color: #0c5460;
}

.alertmsg {
  background: #fff3cd;
  color: #856404;
}

.style-msg .sb-msg,
.style-msg2 .msgtitle,
.style-msg2 .sb-msg {
  display: block;
  padding: 15px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 1rem;
}

.style-msg2 .msgtitle {
  background: rgba(0, 0, 0, 0.15);
  border-top: none;
  border-bottom: none;
  font-weight: bold;
}

.style-msg-light .sb-msg,
.style-msg-light .msgtitle {
  color: #fff;
}

.style-msg2 .sb-msg {
  font-weight: normal;
}

.style-msg2 ol,
.style-msg2 ul {
  margin: 0 0 0 30px;
}

.style-msg2 ol {
  list-style-type: decimal;
}

.style-msg2 ul {
  list-style-type: disc;
}

.alert i,
.sb-msg i,
.msgtitle i {
  position: relative;
  top: 1px;
  font-size: 0.875rem;
  width: 14px;
  text-align: center;
  margin-right: 5px;
}

.style-msg .close {
  position: absolute;
  top: 10px;
  left: auto;
  right: 10px;
}

/* ----------------------------------------------------------------
	Forms
-----------------------------------------------------------------*/

.sm-form-control {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 14px;
  font-size: 15px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #ddd;
  border-radius: 0 !important;
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}

.sm-form-control:focus {
  border-color: #aaa;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sm-form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.sm-form-control:-ms-input-placeholder {
  color: #999;
}
.sm-form-control::-webkit-input-placeholder {
  color: #999;
}

.sm-form-control[disabled],
.sm-form-control[readonly],
fieldset[disabled] .sm-form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
  opacity: 1;
}

.sm-form-control {
  height: auto;
}

.form-control {
  border-radius: 3px;
}

.form-control:active,
.form-control:focus {
  border-color: #999;
  box-shadow: none;
}

label {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  font-family: "Nunito", sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #555;
  margin-bottom: 10px;
  cursor: pointer;
}

label.label-muted {
  color: #999;
  font-weight: normal;
  margin-right: 5px;
}

/* ----------------------------------------------------------------
	Contact Form
-----------------------------------------------------------------*/

.contact-form-overlay {
  position: relative;
  background-color: #fff;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

label.error {
  margin-top: 5px;
  color: #e42c3e;
  font-weight: 400;
}

label.error {
  display: none !important;
}

.show-error-msg + label.error {
  display: block !important;
}

input[type="checkbox"]:not(:checked) + label.error {
  display: block !important;
}

.form-control.error,
.sm-form-control.error {
  border-color: #e42c3e;
}

#footer {
  position: relative;
  background-color: #eee;
  border-top: 5px solid rgba(0, 0, 0, 0.2);
}

@media (min-width: 992px) {
  .sticky-footer #slider:not(.slider-parallax-invisible),
  .sticky-footer #page-submenu,
  .sticky-footer #page-title,
  .sticky-footer #content {
    z-index: 2;
  }

  .sticky-footer #footer {
    position: sticky;
    top: auto;
    bottom: 0;
    left: 0;
  }
}

#footer .footer-widgets-wrap {
  position: relative;
  padding: 80px 0;
}

#copyrights {
  padding: 60px 0 40px;
  background-color: #ddd;
  font-size: 0.875rem;
  line-height: 1.8;
}

#copyrights i.footer-icon {
  position: relative;
  top: 1px;
  font-size: 0.875rem;
  width: 14px;
  text-align: center;
  margin-right: 3px;
}

.copyright-links a {
  display: inline-block;
  margin: 0 3px;
  color: #333;
  border-bottom: 1px dotted #444;
}

.copyright-links a:hover {
  color: #555;
  border-bottom: 1px solid #666;
}

.copyrights-menu > a {
  margin: 0 7px;
}

.copyrights-menu a {
  font-size: 0.875rem;
  border-bottom: 0 !important;
}

#copyrights a:first-child {
  margin-left: 0;
}

#copyrights.text-right a:last-child {
  margin-right: 0;
}

.footer-logo {
  display: block;
  margin-bottom: 30px;
}

.svg-trigger {
  width: 30px;
  height: 30px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.svg-trigger path {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #333;
  stroke-width: 5;
  stroke-linecap: round;
}

.svg-trigger path:nth-child(1) {
  stroke-dasharray: 40 160;
}

.svg-trigger path:nth-child(2) {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}

.svg-trigger path:nth-child(3) {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}

@media (max-width: 767px) {
  .svg-trigger {
    position: relative;
    right: -7px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: unset;
    padding: 0 30px;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: unset;
    padding: 0 20px;
  }
}

.elipsis-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: wrap;
  overflow-wrap: break-word;
}

@supports (-webkit-line-clamp: 2) {
  /* Needs .elipsis also */
  .elipsis-2-lines {
    white-space: initial !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.word-wrap-break {
  word-wrap: break-word;
}
