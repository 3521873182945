.font-primary {
  font-family: "Nunito", sans-serif !important;
}

.font-secondary {
  font-family: "Nunito", sans-serif !important;
}

@for $i from 11 to 57 {
  .fs-#{$i}px {
    font-size: #{$i}px;
  }
}

@for $i from 11 to 57 {
  @media screen and (max-width: 789px) {
    .fs-mobile-#{$i}px {
      font-size: #{$i}px;
    }
  }
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.td-u {
  text-decoration: underline !important;
}

.tt-u {
  text-transform: uppercase;
}
