@import "../../../../styles/variables";

.search-input {
  font-size: 13px !important;
  height: 40px !important;

  &::placeholder {
    color: $gray !important;
    font-size: 12px;
  }
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

.search-icon {
  position: absolute;
  top: 8px;
  right: 10px;

  @media screen and (min-width: 768px) {
    display: none !important;
  }
}
