@import "../../../../styles/variables";

.artplacer-logo {
  width: 194px;
  height: 37px;
  background-position: center center;
  background-image: url("/artplacer-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  @media screen and (max-width: 767px) {
    position: absolute;
    left: -10px;
  }
}

.search-input {
  font-size: 13px !important;
  height: 40px !important;

  @media screen and (max-width: 767px) {
    display: none !important;
  }

  &::placeholder {
    color: $gray !important;
    font-size: 12px;
  }
}

.search-icon {
  position: absolute;
  top: 8px;
  right: 10px;

  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
