.header-border {
  border-bottom: 1px solid #d9d9d9;
}

.menu-item {
  font-weight: bold;
}

.border-item {
  width: 18px;
  border-bottom: 3px solid #e31e36;
  margin: 0 auto;
}

.header {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 9999;
}
