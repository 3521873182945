@import "./variables";

/* Colors */
.color-gray-light {
  color: $gray-2 !important;
}

.color-gray-super-light {
  color: $gray-super-light;
}

.color-gray-dark {
  color: $gray-dark;
}

.color-black {
  color: $black;
}

.color-white {
  color: $white;
}

.color-red {
  color: $red;
}
